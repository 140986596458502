import { db, fn } from "@kanpla/system";
import { AnonymousPropsFlow } from "@kanpla/types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { SignUpFlowContext } from "..";
import KanplaCard from "../../forms/child/KanplaCard";
import PageWrapper from "../PageWrapper";

const AddCard = ({
  isFromAnonymousFlow = false,
  isFromAPrivateModule = false,
}: AnonymousPropsFlow) => {
  const { t } = useTranslation(["signup-flow", "translation"]);
  const { child } = useContainer(SignUpFlowContext);
  const [haveCard, setHaveCard] = useState(true);
  const [kanplaId, setKanplaId] = useState("");

  const submit = async () => {
    if (!haveCard) return;

    if (!kanplaId) {
      throw new Error(t("signup-flow:message.error.enter-card-id"));
    }

    const checkId = fn.httpsCallable("idExistsEurope");
    const exists = await checkId({ kanplaId: parseInt(kanplaId) }).then(
      (r) => r.data
    );

    if (exists) {
      throw new Error(t("translation:message.error.kanpla-id-already-used"));
    }

    await db
      .collection("children")
      .doc(child.id)
      .update({
        kanplaId: parseInt(kanplaId),
      });
  };

  return (
    <PageWrapper
      title={t("signup-flow:kanpla-card")}
      subtitle={t("signup-flow:card-can-be-used-to-get-food")}
      buttonTitle={
        haveCard ? t("translation:add-card-short") : t("translation:continue")
      }
      topIllustration="/images/signup-flow/kanpla-card.svg"
      onButtonSubmit={submit}
      isFromAnonymousFlow={isFromAnonymousFlow}
      isFromAPrivateModule={isFromAPrivateModule}
    >
      <div className="w-full h-full mt-16">
        <KanplaCard
          haveCard={haveCard}
          setHaveCard={setHaveCard}
          kanplaId={kanplaId}
          setKanplaId={setKanplaId}
        />
      </div>
    </PageWrapper>
  );
};

export default AddCard;
