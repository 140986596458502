import {
  callFunction,
  db,
  fn,
  getActivePlugins,
  getChildGroupName,
  hasAccessToModule,
  useCollectionListener,
  useLocationHostname,
} from "@kanpla/system";
import {
  AnonymousPropsFlow,
  Child,
  DomainInfo,
  DomainInfoSchools,
  Module,
  PopupConstructor,
  ProviderNames,
  Providers,
  School,
} from "@kanpla/types";
import { message } from "antd";
import { useDocumentListener } from "libs/system/src/firestore/UseDocumentListener";
import { isEmpty } from "lodash";
import { StringParam, useQueryParam } from "next-query-params";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useState } from "react";
import { useLocalstorageState } from "rooks";
import { createContainer, useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import AccessCode from "./screens/AccessCode";
import AccountAlreadyExists from "./screens/AccountAlreadyExists";
import AddCard from "./screens/AddCard";
import AddChild from "./screens/AddChild";
import CanteenId from "./screens/CanteenId";
import ChooseSchool from "./screens/ChooseSchools";
import CompanyNotExisting from "./screens/CompanyNotExisting";
import Completed from "./screens/Completed";
import Email from "./screens/Email";
import EmailCode from "./screens/EmailCode";
import KanplaCard from "./screens/KanplaCard";
import Provider from "./screens/Provider";
import Reference from "./screens/Reference";
import Register from "./screens/Register";
import Salesplace from "./screens/Salesplace";
import SchoolOrCompany from "./screens/SchoolOrCompany";
import StandardOrder from "./screens/StandardOrder";
import View from "./View";

interface Sections {
  [sectionName: string]: {
    active: boolean;
    content: ReactNode;
  };
}

export interface Props extends AnonymousPropsFlow {
  throughInvitation?: boolean;
  showSidebar?: boolean;
  providerName?: ProviderNames;
  schoolId?: string;
}

/** HARDCODED! */
export const domainsWithPublicSchools = [
  "dabba.kanpla.dk",
  "bistrupognoer.kanpla.dk",
  "thecantina.kanpla.dk",
  "viborgpay.kanpla.dk",
  "smoerrebroed.kanpla.dk",
  "martinkok.kanpla.dk",
  "spispaent.kanpla.dk",
];

export const kanplaDomains = [
  "kanpla.dk",
  "app.kanpla.dk",
  "kanpla.io",
  "next.kanpla.dk",
  "localhost",
];

const domainSignupConfig = {
  switch: [
    ...kanplaDomains,
    "thecantina.kanpla.dk",
    "martinkok.kanpla.dk",
    "dabba.kanpla.dk",
  ],
  list: [
    "bistrupognoer.kanpla.dk",
    "viborgpay.kanpla.dk",
    "smoerrebroed.kanpla.dk",
    "spispaent.kanpla.dk",
  ],
};

const ContextState = (props: Props) => {
  const { throughInvitation = false, showSidebar = true } = props;
  /** Current View */
  const [selectedPage, setCurrentPage] = useQueryParam("step", StringParam);
  /** Determines the type of input field (company email / any email) */
  const [onlyCompanySignup, setOnlyCompanySignup] = useState<boolean>(false);
  /** Available signup providers (e.g. Microsoft Azure) */
  const [providers, setProviders] = useState<Array<Providers>>([]);
  /** If email domain has more than one school associated to it  */
  const [multiSchoolsEmail, setMultiSchoolsEmail] = useState<DomainInfoSchools>(
    []
  );
  /** Load the screens for the signup carousel on mobile */
  const [signupScreens, setSignupScreens] = useState<
    PopupConstructor.Fullscreen[]
  >([]);
  /** Current domains info */
  const [domainsInfo, setDomainsInfo] = useState<DomainInfo[]>([]);

  const { auth } = useContainer(AppContext);
  const userId = auth?.user?.uid;

  const hostname = useLocationHostname({});
  const hasPublicSchools = domainsWithPublicSchools.includes(hostname);

  const hasSchoolSwitch = domainSignupConfig.switch.includes(hostname);
  const hasSchoolList = domainSignupConfig.list.includes(hostname);
  const hasNewsletter = kanplaDomains.includes(hostname);

  useEffect(() => {
    setOnlyCompanySignup(!hasPublicSchools && !hasSchoolSwitch);
    setCurrentPage(
      hasSchoolSwitch
        ? "schoolOrCompany"
        : hasSchoolList
        ? "salesplace"
        : "email"
    );
  }, [hasPublicSchools, hasSchoolSwitch]);

  const { setChildId, customBranding, isChildSalesplace } =
    useContainer(AppContext);

  // Local child Data (before registration)
  const [schoolId, setSchoolId] = useState<string>(null);
  const [school] = useDocumentListener<School>({
    collection: "schools",
    id: schoolId,
  });

  const [email, setEmail] = useState<string>(null);
  const [selectors, setSelectors] = useState<Child["selectors"]>(Object);
  const [name, setName] = useState<Child["name"]>(null);
  const [scope, setScope] = useState<Child["scope"]>(null);
  const groupName = getChildGroupName(selectors);

  // Database child (after registration)

  const [children] = useCollectionListener<Child>(
    db.collection("children").where("userId", "==", userId || "—")
  );
  const child = children?.[0];

  // After navigating to /app
  const [pageLoading, setPageLoading] = useState<boolean>(false);

  const router = useRouter();

  const [id, setId] = useLocalstorageState<string>("current_child_id");

  const loadSignupScreens = async (schoolId: string) => {
    try {
      const screens = (await callFunction("popups-fetchPopupScreens", {
        schoolId,
      })) as PopupConstructor.Fullscreen[];

      setSignupScreens(screens);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!schoolId) return;
    loadSignupScreens(schoolId);
  }, [schoolId]);

  const createChild = async () => {
    try {
      if (!userId) return;

      // Create child
      const newChild = await callFunction("signup-createChild", {
        name,
        schoolId,
        selectors,
        userId,
        codes: [],
        isChildSalesplace,
        scope,
      });

      // Set child data
      setId(newChild.id);
      setChildId(newChild?.id as any);
    } catch (err) {
      console.error(err);
      message.error(err.message);
    }
  };

  const isSchoolForChildren =
    school?.type === "school" ||
    school?.type === "schoolSupplier" ||
    school?.type === "highSchool";

  const [schoolModules = []] = useCollectionListener<Module>(
    db
      .collection("modules")
      .where("scope.generatedSchoolIds", "array-contains", school?.id || "")
  );
  const hasKanplaGo = schoolModules.some(
    (module) => module?.plugins?.kanplaGo?.active
  );

  const hasAccessCodes =
    schoolModules.some(
      (module) =>
        module.config?.codes?.length > 0 || module.config?.bulkCodes?.length > 0
    ) ||
    Object.values(school?.modules || {}).some(
      (v) => v?.codes?.length > 0 || v?.bulkCodes?.length > 0
    );

  const hasKanplaCard = school?.hasCards;

  const hasReferencePlugin = schoolModules.some(
    (module) =>
      getActivePlugins({
        module,
        schoolId,
        groupName,
      }).invoiceReference
  );
  const flexModule = schoolModules.find(
    (module) =>
      module.type === "flex" &&
      module.flow !== "menuPreview" &&
      hasAccessToModule({ school, module, child })?.individual
  );

  const allScreens: Sections = {
    schoolOrCompany: {
      active: hasSchoolSwitch,
      content: (
        <SchoolOrCompany
          isFromAnonymousFlow={props.isFromAnonymousFlow}
          isFromAPrivateModule={props.isFromAPrivateModule}
        />
      ),
    },
    salesplace: {
      active: hasPublicSchools || hasSchoolSwitch,
      content: (
        <Salesplace
          isFromAnonymousFlow={props.isFromAnonymousFlow}
          isFromAPrivateModule={props.isFromAPrivateModule}
        />
      ),
    },
    email: {
      active: true,
      content: (
        <Email
          isFromAnonymousFlow={props.isFromAnonymousFlow}
          isFromAPrivateModule={props.isFromAPrivateModule}
        />
      ),
    },
    emailCode: {
      active: false,
      content: (
        <EmailCode
          isFromAnonymousFlow={props.isFromAnonymousFlow}
          isFromAPrivateModule={props.isFromAPrivateModule}
        />
      ),
    },
    chooseSchool: {
      active: true,
      content: (
        <ChooseSchool
          isFromAnonymousFlow={props.isFromAnonymousFlow}
          isFromAPrivateModule={props.isFromAPrivateModule}
        />
      ),
    },
    /** Out of flow */
    accountAlreadyExists: {
      active: false,
      content: (
        <AccountAlreadyExists
          isFromAnonymousFlow={props.isFromAnonymousFlow}
          isFromAPrivateModule={props.isFromAPrivateModule}
        />
      ),
    },
    /** Out of flow */
    companyNotExisting: {
      active: false,
      content: (
        <CompanyNotExisting
          isFromAnonymousFlow={props.isFromAnonymousFlow}
          isFromAPrivateModule={props.isFromAPrivateModule}
        />
      ),
    },
    /** Out of flow */
    provider: {
      active: false,
      content: (
        <Provider
          isFromAnonymousFlow={props.isFromAnonymousFlow}
          isFromAPrivateModule={props.isFromAPrivateModule}
        />
      ),
    },
    canteenId: {
      active: false,
      content: (
        <CanteenId
          isFromAnonymousFlow={props.isFromAnonymousFlow}
          isFromAPrivateModule={props.isFromAPrivateModule}
        />
      ),
    },
    user: {
      active: true,
      content: (
        <Register
          isFromAnonymousFlow={props.isFromAnonymousFlow}
          isFromAPrivateModule={props.isFromAPrivateModule}
        />
      ),
    },
    addChild: {
      active: isSchoolForChildren,
      content: (
        <AddChild
          isFromAnonymousFlow={props.isFromAnonymousFlow}
          isFromAPrivateModule={props.isFromAPrivateModule}
        />
      ),
    },
    /** Out of flow for Serwiz */
    addCard: {
      active: false,
      content: (
        <AddCard
          isFromAnonymousFlow={props.isFromAnonymousFlow}
          isFromAPrivateModule={props.isFromAPrivateModule}
        />
      ),
    },
    accessCodes: {
      active: (school?.type === "companySupplier" || false) && hasAccessCodes,
      content: (
        <AccessCode
          isFromAnonymousFlow={props.isFromAnonymousFlow}
          isFromAPrivateModule={props.isFromAPrivateModule}
        />
      ),
    },
    kanplaCard: {
      active: hasKanplaCard,
      content: (
        <KanplaCard
          isFromAnonymousFlow={props.isFromAnonymousFlow}
          isFromAPrivateModule={props.isFromAPrivateModule}
        />
      ),
    },
    standards: {
      active: !isEmpty(flexModule),
      content: (
        <StandardOrder
          isFromAnonymousFlow={props.isFromAnonymousFlow}
          isFromAPrivateModule={props.isFromAPrivateModule}
          module={flexModule}
        />
      ),
    },
    reference: {
      active: hasReferencePlugin,
      content: (
        <Reference
          isFromAnonymousFlow={props.isFromAnonymousFlow}
          isFromAPrivateModule={props.isFromAPrivateModule}
        />
      ),
    },
    completed: {
      active: true,
      content: (
        <Completed
          isFromAnonymousFlow={props.isFromAnonymousFlow}
          isFromAPrivateModule={props.isFromAPrivateModule}
        />
      ),
    },
  };

  const pages: Array<string> = Object.keys(allScreens || {}).filter(
    (key) => allScreens[key]?.active
  );
  const currentPage = selectedPage || pages[0];
  const currentIndex = pages.indexOf(currentPage);

  const goToNextScreen = () => {
    const nextIndex = currentIndex + 1;
    const nextPage = pages[nextIndex];

    if (nextPage) {
      setCurrentPage(nextPage);
    } else {
      setPageLoading(true);
      router.push("/app");
    }
  };

  const submitEmail = async ({ email, avoidProviders = false }) => {
    setEmail(email);

    try {
      const callable = fn.httpsCallable("signup-validateEmail");
      const { data } = await callable({
        email,
        onlyCompanySignup,
        avoidProviders,
        partnerId: customBranding?.partnerId || null,
        // If schoolId exists, send it to check if they don't have a company email domain
        schoolId,
      });

      setDomainsInfo(data);

      // If has multiple schools registered to an email domain
      if (Array.isArray(data)) {
        const alreadyExists = (data as DomainInfoSchools).find(
          (d) => d?.alreadyExists
        );

        const localProviders = (alreadyExists?.providers || []).filter(
          (p) => !p.onlyLogin
        );

        if (alreadyExists) {
          setCurrentPage("accountAlreadyExists");
          if (localProviders?.length > 0) {
            setProviders(localProviders);
          }
          return;
        }

        if (localProviders?.length > 0) {
          setProviders(localProviders);
          setCurrentPage("provider");
          return;
        }

        const needsVerification = data.some((d) => d.needsVerification);

        setMultiSchoolsEmail(data);
        setCurrentPage(
          needsVerification
            ? "emailCode"
            : data.length > 1
            ? "chooseSchool"
            : "user"
        );
        return;
      }

      setMultiSchoolsEmail([data]);

      const {
        schoolId: resultSchoolId,
        needsVerification,
        providers: allLocalProviders,
        alreadyExists,
      } = data;

      const localProviders = (allLocalProviders || []).filter(
        (p: Providers) => !p.onlyLogin
      );

      if (alreadyExists) {
        setCurrentPage("accountAlreadyExists");
        if (localProviders?.length > 0) {
          setProviders(localProviders);
        }
        return;
      }

      if (resultSchoolId) setSchoolId(resultSchoolId);

      if (localProviders?.length > 0) {
        setProviders(localProviders);
        setCurrentPage("provider");
        return;
      }

      setProviders(localProviders);

      setCurrentPage(needsVerification ? "emailCode" : "user");
    } catch (err) {
      if (err.code === "not-found") {
        setCurrentPage("companyNotExisting");
        return;
      }
      console.error(err);
      message.error({
        content: (
          <span data-cy="not-existing-company-error">{err?.message}</span>
        ),
      });
    }
  };

  return {
    school,
    schoolId,
    setSchoolId,
    selectors,
    setSelectors,
    name,
    setName,
    schoolModules,

    multiSchoolsEmail,
    setMultiSchoolsEmail,

    pages,
    currentPage,
    setCurrentPage,

    currentIndex,

    isSchoolForChildren,
    hasKanplaGo,
    child,
    goToNextScreen,
    createChild,
    throughInvitation,
    email,
    setEmail,
    pageLoading,
    allScreens,
    onlyCompanySignup,
    setOnlyCompanySignup,
    submitEmail,
    providers,
    showSidebar,
    userId,

    signupScreens,
    setSignupScreens,
    scope,
    setScope,

    hasPublicSchools,

    domainsInfo,
    setDomainsInfo,

    hasNewsletter,
  };
};

export const SignUpFlowContext = createContainer(ContextState);

const Index = (props: Props) => (
  <SignUpFlowContext.Provider initialState={props}>
    <View {...props} />
  </SignUpFlowContext.Provider>
);

export default Index;
