import React, { ReactNode } from "react";

export type PageHeaderProps = {
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  titleDataCy?: string;
  titleSize?: "h500" | "h600" | "h700" | "h800";
};

const PageHeader = ({
  title,
  titleDataCy = "signup-screen-generic-title",
  subtitle,
  titleSize = "h700",
}: PageHeaderProps) => {
  return (
    <div>
      {" "}
      <h1
        className={`${titleSize} text-center text-primary-main`}
        data-cy={titleDataCy}
      >
        {title}
      </h1>
      {subtitle && (
        <p className="text-center text-sm mt-0 text-text-secondary">
          {subtitle}
        </p>
      )}
    </div>
  );
};

export default PageHeader;
