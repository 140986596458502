import { AnonymousPropsFlow } from "@kanpla/types";
import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { SignUpFlowContext } from "..";
import { AppContext } from "../../contextProvider";
import PageWrapper from "../PageWrapper";

const CompanyNotExisting = ({
  isFromAnonymousFlow = false,
  isFromAPrivateModule = false,
}: AnonymousPropsFlow) => {
  const { t } = useTranslation(["signup-flow"]);
  const { customBranding } = useContainer(AppContext);
  const { setCurrentPage } = useContainer(SignUpFlowContext);

  return (
    <PageWrapper
      title={t("signup-flow:no-company-for-email")}
      buttonTitle=""
      hideButton={true}
      showBackButton={true}
      isFromAnonymousFlow={isFromAnonymousFlow}
      isFromAPrivateModule={isFromAPrivateModule}
    >
      <div className="text-center pb-12">
        <Button type="primary" onClick={() => setCurrentPage("email")}>
          {t("signup-flow:use-other-company-email")}
        </Button>
        <p className="text-sm text-text-secondary pt-3">
          {t("signup-flow:company-email-give-you-access")}
        </p>
      </div>
      <div className="text-center pb-8">
        <Button onClick={() => setCurrentPage("canteenId")}>
          {t("signup-flow:log-in-with-canteen-id")}
        </Button>
        <p className="text-sm text-text-secondary pt-3">
          {t("signup-flow:can-still-access-with-code", {
            value: customBranding?.name,
          })}
        </p>
      </div>
    </PageWrapper>
  );
};

export default CompanyNotExisting;
