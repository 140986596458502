import { AnonymousPropsFlow } from "@kanpla/types";
import { Image } from "@kanpla/ui";
import { Button } from "antd";
import { useRouter } from "next/router";
import React from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { SignUpFlowContext } from "..";
import PageWrapper from "../PageWrapper";

export const providerSetup = {
  "microsoft.com": { name: "Microsoft", id: "microsoft.com" },
};

const Provider = ({
  isFromAnonymousFlow = false,
  isFromAPrivateModule = false,
}: AnonymousPropsFlow) => {
  const { t } = useTranslation(["signup-flow", "translation"]);
  const {
    email,
    submitEmail,
    providers = [],
    schoolId,
  } = useContainer(SignUpFlowContext);

  const router = useRouter();

  const doProvider = async (providerName: string) => {
    router.push(`/signup/${providerName}/${schoolId || "null"}/${email}`);
  };

  return (
    <PageWrapper
      title={t("translation:access-your-canteen")}
      buttonTitle=""
      hideButton={true}
      showBackButton={true}
      isFromAnonymousFlow={isFromAnonymousFlow}
      isFromAPrivateModule={isFromAPrivateModule}
    >
      <div className="text-center pb-12">
        {providers.map((provider) => (
          <Button
            key={provider.name}
            onClick={() => doProvider(provider.name)}
            size="large"
            className="flex mx-auto"
            icon={
              <Image
                src={`/images/logos/${provider.name}.svg`}
                alt={`${provider.name} Logo`}
                className="mr-3"
              />
            }
          >
            {t("signup-flow:sign-up-with", {
              value: providerSetup[provider.name]?.name || provider.name,
            })}
          </Button>
        ))}

        <p className="text-sm text-text-secondary pt-3">
          {t("signup-flow:you-will-be-redirect")}
        </p>
      </div>
      {!providers.some((p) => p.disablePassword) && (
        <div className="text-center pb-8">
          <Button onClick={() => submitEmail({ email, avoidProviders: true })}>
            {t("signup-flow:use-password")}
          </Button>
          <p className="text-sm text-text-secondary pt-3">
            {t("signup-flow:create-account-manually")}
          </p>
        </div>
      )}
    </PageWrapper>
  );
};

export default Provider;
