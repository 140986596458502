import { db } from "@kanpla/system";
import { AnonymousPropsFlow } from "@kanpla/types";
import { FormInput, InvoiceReference } from "@kanpla/ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { SignUpFlowContext } from "..";
import { AppContext } from "../../contextProvider";
import PageWrapper from "../PageWrapper";

const Reference = ({
  isFromAnonymousFlow = false,
  isFromAPrivateModule = false,
}: AnonymousPropsFlow) => {
  const { t } = useTranslation(["signup-flow", "translation"]);
  const { triggerReload } = useContainer(AppContext);
  const { child, schoolModules } = useContainer(SignUpFlowContext);

  const submit = async (data) => {
    await db.collection("children").doc(child.id).update({
      defaultReference: data.invoiceReference,
    });
    triggerReload();
  };

  const module = schoolModules.find(
    (module) => module?.plugins?.invoiceReference?.active
  );

  const title =
    module?.plugins?.invoiceReference?.title ||
    t("signup-flow:reference-number");
  const description =
    module?.plugins?.invoiceReference?.description ||
    t("signup-flow:insert-number-to-help");

  const isRequired =
    module?.plugins?.invoiceReference?.defaultRequired || false;

  return (
    <PageWrapper
      title={title}
      subtitle={description}
      buttonTitle={t("signup-flow:add-reference-number")}
      topIllustration="/images/signup-flow/reference.svg"
      onButtonSubmit={submit}
      skipButton={!isRequired}
      isFromAnonymousFlow={isFromAnonymousFlow}
      isFromAPrivateModule={isFromAPrivateModule}
    >
      <div className="w-full h-full mt-16">
        <InvoiceReference
          noBg
          module={module}
          defaultReference={null}
          hideHeader={true}
        />
      </div>
    </PageWrapper>
  );
};

export default Reference;
