import { antdFilterOptionSearch, db } from "@kanpla/system";
import { School, Supplier } from "@kanpla/types";
import { Select } from "antd";
import { useEffect, useState } from "react";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

interface Props {
  school: School;
  schools: Array<School>;
  setSchool: (newSchool: School) => void;
  edit?: boolean;
  company?: boolean;
  allowedSchools?: Array<string>;
  loading?: boolean;
}

const SelectSchool = ({
  school,
  schools,
  allowedSchools,
  setSchool,
  edit,
  company,
  loading,
}: Props) => {
  const { t } = useTranslation(["translation", "forms", "signup-flow"]);
  // Search Functionality
  const [selected, setSelected] = useState<School>(school || null);
  const [options, setOptions] = useState<Array<School>>([]);
  const { supplier } = useContainer(AppContext);

  const [allSuppliers = [], allSuppliersLoading] =
    useCollectionDataOnce<Supplier>(
      !company ? db.collection("suppliers").orderBy("url") : null
    );

  useEffect(() => {
    setSelected(school);
  }, [school?.id]);

  useEffect(() => {
    if (allSuppliersLoading) return;
    const sorted = schools
      .sort((a, b) => a.name && a.name.localeCompare(b.name || ""))
      .filter((s) => {
        if (!s?.id || s?.deleted) return false;

        if (s?.id === school?.id || allowedSchools?.includes(s?.id))
          return true;

        // Filter out hidden schools
        if (s.hidden) return false;

        // Filter schools and custom items
        if (!company)
          return !allSuppliers
            .map((supplier) => supplier.partnerId)
            .includes(s.partnerId);

        return s.partnerId === supplier?.partnerId;
      });
    setOptions(sorted);
  }, [schools, allSuppliersLoading]);

  return (
    <Select
      loading={loading}
      size="large"
      placeholder={t("forms:form.placeholders.search-by", {
        value: company
          ? t("translation:values.company-name")
          : t("translation:values.school-name"),
      })}
      value={selected?.id}
      className="w-full"
      id="selectSchool"
      showSearch
      filterOption={antdFilterOptionSearch}
      disabled={!!edit}
      onChange={(schoolId) => {
        const selectedSchool = schools.find((s) => s.id === schoolId);
        setSelected(selectedSchool);
        setSchool(selectedSchool);
      }}
      options={options.map((option) => ({
        value: option?.id,
        label: `${option.name}${
          option?.contact?.zip ? `, ${option.contact.zip}` : ""
        }`,
      }))}
    />
  );
};

export default SelectSchool;
