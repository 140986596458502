import { Spinner } from "@kanpla/ui";
import { Button } from "antd";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  title: string | ReactNode;
  size?: "auto" | "full";
  height?: "medium" | "small";
  onClick?: (p?: any) => void | Promise<void>;
  loading?: boolean;
  loadingText?: string;
  type?: "submit" | "button";
  disabled?: boolean;
}

const ButtonSignUp = (props: Props) => {
  const { t } = useTranslation(["translation"]);
  const {
    title,
    size = "full",
    onClick = () => null,
    loading,
    loadingText = t("translation:loading"),
    height = "medium",
    type = "submit",
    disabled,
  } = props;

  return (
    <Button
      id="signupButtonContinue"
      type="primary"
      size="large"
      className={`${loading ? "animate-pulse" : ""} ${
        height === "medium" ? "h-14" : "h-12"
      } mt-4 hover:shadow transition-opacity cursor-pointer p-4 flex justify-center items-center w-${size}`}
      htmlType={type}
      onClick={onClick}
      disabled={disabled}
    >
      {loading && (
        <div className="mr-2">
          <Spinner size="small" color="#FFFFFF" />
        </div>
      )}
      {loading ? loadingText : title}
    </Button>
  );
};

export default ButtonSignUp;
