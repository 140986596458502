import React from "react";
import { useContainer } from "unstated-next";
import { Props, SignUpFlowContext } from ".";
import LandingWrapper from "../LandingWrapper";
import AutomaticallySetSchool from "./AutomaticallySetSchool";
import Loading from "./Loading";
import MagicLinkListener from "./MagicLinkListener";

const View = (props: Props) => {
  const { pageLoading, allScreens, currentPage } =
    useContainer(SignUpFlowContext);

  if (props.isFromAnonymousFlow)
    return (
      <AutomaticallySetSchool>
        <MagicLinkListener>
          {!pageLoading ? (
            <div className="h-full w-full">
              {allScreens[currentPage]?.content}
            </div>
          ) : (
            <Loading />
          )}
        </MagicLinkListener>
      </AutomaticallySetSchool>
    );

  return (
    <AutomaticallySetSchool>
      <MagicLinkListener>
        <LandingWrapper>
          {!pageLoading ? (
            <div className="h-full w-full">
              {allScreens[currentPage]?.content}
            </div>
          ) : (
            <Loading />
          )}
        </LandingWrapper>
      </MagicLinkListener>
    </AutomaticallySetSchool>
  );
};

export default View;
