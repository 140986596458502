import { configLabel } from "@kanpla/system";
import { Button, Form, Input, Popconfirm } from "antd";
import { useTranslation } from "react-i18next";
import React, { Dispatch, SetStateAction } from "react";

interface Props {
  haveCard?: boolean;
  setHaveCard: Dispatch<SetStateAction<boolean>>;
  kanplaId: string | number;
  setKanplaId: Dispatch<SetStateAction<string | number>>;
}

const KanplaCard = (props: Props) => {
  const { kanplaId, setKanplaId, haveCard, setHaveCard } = props;
  const { t } = useTranslation(["translation", "forms"]);
  return (
    <div className="mb-6">
      <Form.Item
        label={t("translation:form.labels.kanpla-card-id")}
        name="card-input"
        className="mb-0"
        rules={[
          {
            required: haveCard,
            message: t("translation:form.errors.required", { value: "ID" }),
          },
        ]}
        labelCol={configLabel({ span: 24 })}
        initialValue={kanplaId}
      >
        <div className="flex items-center">
          <Input
            className={`${!haveCard && "opacity-50"}`}
            id="card-input"
            type="number"
            placeholder={t("translation:form.placeholders.eg")}
            value={kanplaId}
            onChange={(e) => setKanplaId(e.target.value)}
            onFocus={() => {
              setHaveCard(true);
            }}
            min="100000"
            max="130000"
          />
          <Popconfirm
            title={t("translation:no-card-question")}
            // subtitle="Du kan altid tilføje et kort senere. ☺️ Derudover er der
            // også mulighed for at hente maden med telefonen eller uden
            // kort gennem udskrevne lister på de fleste skoler."
            onConfirm={() => {
              setHaveCard(false);
              setKanplaId("");
            }}
          >
            <Button
              disabled={!haveCard}
              className={`whitespace-nowrap ml-2`}
              htmlType="button"
              data-cy="no-kanpla-card-btn"
            >
              {t("translation:no-card")}
            </Button>
          </Popconfirm>
        </div>
      </Form.Item>
      <p className="text-sm text-text-secondary mb-4 mt-1 opacity-70">
        {t("forms:canteen-scans")}
      </p>
    </div>
  );
};

export default KanplaCard;
