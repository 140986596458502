import { db } from "@kanpla/system";
import { School } from "@kanpla/types";
import { Spinner } from "@kanpla/ui";
import { useRouter } from "next/router";
import React, { ReactNode, useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { SignUpFlowContext } from ".";

interface Props {
  children: ReactNode;
}

/** Note: Only active on invitation flow */

const AutomaticallySetSchool = (props: Props) => {
  const { throughInvitation, setSchoolId, setCurrentPage } =
    useContainer(SignUpFlowContext);

  // Get schoolId from the query
  const router = useRouter();
  const { schoolId } = router.query;

  const [loading, setLoading] = useState(true);

  const checkIfSchoolExists = async (schoolId: string) => {
    try {
      const res = await db.collection("schools").doc(schoolId).get();

      // If no school, return to the login screen
      if (!res.exists) {
        router.push("/login");
        return;
      }

      // Otherwise set the school to the sign-up-flow context
      const school = {
        ...res.data(),
        id: res.id,
        ref: res.ref,
      } as unknown as School;
      setSchoolId(school.id);
      setCurrentPage("email");
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!throughInvitation) return;
    if (schoolId) checkIfSchoolExists(schoolId.toString());
  }, [schoolId]);

  if (loading && throughInvitation) {
    return (
      <div className="flex h-full w-full justify-center items-center">
        <Spinner size="medium" />
      </div>
    );
  }

  return <>{props.children}</>;
};

export default AutomaticallySetSchool;
