import { auth, rules } from "@kanpla/system";
import { AnonymousPropsFlow, ProviderNames } from "@kanpla/types";
import { Image, Spinner } from "@kanpla/ui";
import { Button, Form, Input, message } from "antd";
import { processProviders } from "apps/frontend/lib/signup/processProviders";
import { fetchSignInMethodsForEmail } from "firebase/auth";
import { useTranslation } from "react-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { SignUpFlowContext } from "..";
import { AppContext } from "../../contextProvider";
import PageWrapper from "../PageWrapper";
import { providerSetup } from "./Provider";

const AccountAlreadyExists = ({
  isFromAnonymousFlow = false,
  isFromAPrivateModule = false,
}: AnonymousPropsFlow) => {
  const { t } = useTranslation(["account-already-exists", "translation"]);
  const { email, providers } = useContainer(SignUpFlowContext);
  const router = useRouter();
  const { auth: authHook } = useContainer(AppContext);
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [methods, setMethods] = useState<Array<ProviderNames>>([]);
  const [loadingMethods, setLoadingMethods] = useState(true);
  /** Set the method name to display loading on the button */
  const [processingMethod, setProcessingMethod] = useState<string>(null);

  const submit = async () => {
    try {
      setLoading(true);
      await authHook.signIn(email, password);
      setLoading(false);
      router.push("/app");
    } catch (e) {
      setLoading(false);
      message.error(e?.message);
      console.error(e);
    }
  };

  const ForgotPassword = ({ text }) => {
    return (
      <div className="mt-3 text-center pt-4 text-sm">
        <Link href="./resetPassword">
          <a>{text}</a>
        </Link>
      </div>
    );
  };

  const fetchMethods = async () => {
    setLoadingMethods(true);
    const allMethods = await fetchSignInMethodsForEmail(auth, email);
    setMethods(allMethods);
    setLoadingMethods(false);
  };

  useEffect(() => {
    fetchMethods();
  }, []);

  const doProvider = async (providerName: string) => {
    setProcessingMethod(providerName);
    try {
      await processProviders(providerName, providers, email);
    } catch (e) {
      console.error(e);
      message.error(e?.message);
    } finally {
      setProcessingMethod(null);
    }
  };

  const hasPassword = methods.includes("password");

  return (
    <PageWrapper
      title={t("account-already-exists:account-already-exists", {
        value: email,
      })}
      titleSize="h500"
      subtitle={
        hasPassword
          ? t("account-already-exists:enter-password")
          : t("account-already-exists:log-in-below")
      }
      buttonTitle={t("translation:log-in")}
      hideButton={!hasPassword}
      onButtonSubmit={submit}
      buttonLoading={loading}
      extraContent={
        hasPassword && (
          <ForgotPassword text={t("account-already-exists:forgot-password")} />
        )
      }
      showBackButton={true}
      stayOnPageAfterSubmit={true}
      htmlType="submit"
      isFromAnonymousFlow={isFromAnonymousFlow}
      isFromAPrivateModule={isFromAPrivateModule}
      dataCy="account-already-exist"
    >
      {loadingMethods && methods.length === 0 && (
        <Spinner className="mx-auto" size="large" useCurrentColor />
      )}
      {methods
        .filter((m) => m !== "password")
        .map((method) => {
          return (
            <Button
              loading={processingMethod === method}
              key={method}
              onClick={() => doProvider(method)}
              size="large"
              className="flex mx-auto"
              icon={
                <Image
                  src={`/images/logos/${method}.svg`}
                  alt={`${method} Logo`}
                  className="mr-3"
                />
              }
            >
              {t("translation:log-in-with", {
                value: providerSetup[method]?.name || method,
              })}
            </Button>
          );
        })}
      {hasPassword && (
        <Form.Item
          label={t("translation:password")}
          name="password"
          rules={[
            rules({
              rule: "required",
              ruleValue: true,
              message: t("translation:form.errors.required-en", {
                value: t("translation:password").toLowerCase(),
              }),
            }),
          ]}
        >
          <Input
            id="password"
            type="password"
            placeholder={t("translation:password")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>
      )}
    </PageWrapper>
  );
};

export default AccountAlreadyExists;
