import { AnonymousPropsFlow } from "@kanpla/types";
import { Button, Form, Input, message } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { SignUpFlowContext } from "..";
import PageWrapper from "../PageWrapper";

const Email = ({
  isFromAnonymousFlow = false,
  isFromAPrivateModule = false,
}: AnonymousPropsFlow) => {
  const { t } = useTranslation([
    "sign-up",
    "translation",
    "signup-flow",
    "reset-password",
  ]);
  const {
    submitEmail,
    setCurrentPage,
    onlyCompanySignup,
    isSchoolForChildren,
  } = useContainer(SignUpFlowContext);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const displayInfo = onlyCompanySignup
    ? {
        title: t("sign-up:write-company-email"),
        subtitle: t("sign-up:direct-access-canteen"),
      }
    : isSchoolForChildren
    ? {
        title: t("sign-up:write-email"),
      }
    : {
        title: t("sign-up:write-email"),
        subtitle: t("sign-up:use-company-email"),
      };

  const ExtraContent = () => (
    <div className={`${isFromAPrivateModule && "mb-8"} text-center mt-12`}>
      <p className="mb-2">{t("sign-up:question-without-company-email")}</p>
      <Button
        id="signupGoWithoutCompanyEmail"
        className="mt-1"
        onClick={() => {
          setCurrentPage("canteenId");
        }}
      >
        {t("signup-flow:log-in-with-canteen-id")}
      </Button>
    </div>
  );

  const onFinish = async ({ email }) => {
    try {
      await form.validateFields();
      setLoading(true);
      await submitEmail({ email });
    } catch (e) {
      const errors = e?.errorFields?.map((f) => f?.errors);
      message.error(errors.join(", ") || e?.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageWrapper
      title={displayInfo.title}
      subtitle={displayInfo.subtitle}
      buttonTitle={t("translation:continue")}
      onButtonSubmit={() => form.submit()}
      extraContent={onlyCompanySignup && <ExtraContent />}
      showBackButton={true}
      stayOnPageAfterSubmit={true}
      buttonLoading={loading}
      htmlType="submit"
      largerWidth
      isFromAnonymousFlow={isFromAnonymousFlow}
      isFromAPrivateModule={isFromAPrivateModule}
    >
      {/* This is a form duplicate @robert-wolf! */}
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: t("translation:form.errors.required-your", {
                value: "email",
              }),
            },
            {
              type: "email",
              message: t("reset-password:message.error.wrong-email-address"),
            },
          ]}
        >
          <Input
            type="email"
            placeholder="Email"
            id="emailInput"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                form.submit();
              }
            }}
          />
        </Form.Item>
      </Form>
    </PageWrapper>
  );
};

export default Email;
