import {
  fetchMultipleDocuments,
  isReactRunningInEmulator,
} from "@kanpla/system";
import { AnonymousPropsFlow, School } from "@kanpla/types";
import { SelectorsInput } from "@kanpla/ui";
import { message } from "antd";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { SignUpFlowContext } from "..";
import { AppContext } from "../../contextProvider";
import SelectSchool from "../../forms/SelectSchool";
import PageWrapper from "../PageWrapper";

const ChooseSchool = ({
  isFromAnonymousFlow = false,
  isFromAPrivateModule = false,
}: AnonymousPropsFlow) => {
  const { t } = useTranslation(["signup-flow", "translation"]);

  const [loading, setLoading] = useState(false);
  const [targetSchools, setTargetSchools] = useState<School[]>([]);

  const { customBranding } = useContainer(AppContext);

  const {
    multiSchoolsEmail,
    schoolId,
    setSchoolId,
    setCurrentPage,
    isSchoolForChildren,
    selectors,
    setSelectors,
  } = useContainer(SignUpFlowContext);

  const selectedSchoolIds = multiSchoolsEmail.map((s) => s.schoolId);

  const loadSchools = async () => {
    try {
      const schoolsToShow = await fetchMultipleDocuments<School>(
        "schools",
        selectedSchoolIds
      );

      setTargetSchools(schoolsToShow);
    } catch (err) {
      console.error(err);
    }
  };

  const submit = () => {
    try {
      setLoading(true);

      if (!schoolId)
        throw new Error(t("signup-flow:message.error.choose-school"));

      const findSchool = targetSchools.find((t) => t.id === schoolId);

      if (!isEmpty(findSchool?.selectors || {}) && isEmpty(selectors))
        throw new Error(t("signup-flow:message.error.choose-selector"));

      setCurrentPage("user");
    } catch (err) {
      message.error(err?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadSchools();
  }, []);

  const allAvailableSelectorOption = useMemo(() => {
    const domainSelectors = multiSchoolsEmail.find(
      (domain) => domain.schoolId === schoolId
    )?.selectors;

    return domainSelectors?.[0]?.options?.length;
  }, [multiSchoolsEmail, schoolId]);

  useEffect(() => {
    if (allAvailableSelectorOption !== 1) return;

    const domainSelectors = multiSchoolsEmail.find(
      (domain) => domain.schoolId === schoolId
    )?.selectors;

    const defaultSelector = domainSelectors.reduce((arr, sel) => {
      const name = sel?.name;
      const firstOption = sel?.options?.[0]?.name;

      arr[name] = firstOption;

      return arr;
    }, {});

    setSelectors(defaultSelector);
  }, [allAvailableSelectorOption]);

  return (
    <PageWrapper
      title={t("signup-flow:find-point-of-sale")}
      buttonTitle={t("translation:continue")}
      onButtonClick={submit}
      showBackButton={true}
      stayOnPageAfterSubmit={true}
      buttonLoading={loading}
      isFromAnonymousFlow={isFromAnonymousFlow}
      isFromAPrivateModule={isFromAPrivateModule}
    >
      <div>
        <SelectSchool
          schools={targetSchools}
          school={targetSchools.find((t) => t.id === schoolId)}
          setSchool={(newSchool) => {
            setSchoolId(newSchool.id);
          }}
          edit={false}
          company={isReactRunningInEmulator || !isEmpty(customBranding?.logo)}
        />

        {!isSchoolForChildren && allAvailableSelectorOption === 1 ? null : (
          <div className="mt-4 w-full">
            <SelectorsInput
              selectors={selectors}
              setSelectors={setSelectors}
              school={targetSchools.find((t) => t.id === schoolId)}
              useExtraSelectors
              extraSelectors={
                multiSchoolsEmail.find((domain) => domain.schoolId === schoolId)
                  ?.selectors
              }
            />
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

export default ChooseSchool;
