import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useContainer } from "unstated-next";
import { SignUpFlowContext } from "..";
import KanplaGoIntro from "../../modals/KanplaGoIntro";
import CardAddButtonWrapper from "../../modals/payment/CardAddButtonWrapper";
import ButtonSignUp from "../ButtonSignUp";
import PageWrapper from "../PageWrapper";
import { AnonymousPropsFlow } from "@kanpla/types";

const AddCard = ({
  isFromAnonymousFlow = false,
  isFromAPrivateModule = false,
}: AnonymousPropsFlow) => {
  const { t } = useTranslation(["signup-flow", "translation"]);
  const { hasKanplaGo, goToNextScreen, schoolModules } =
    useContainer(SignUpFlowContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [kanplaGoIntroOpen, setKanplaGoIntroOpen] = useState(false);

  const continueFlow = () => {
    if (hasKanplaGo) setKanplaGoIntroOpen(true);
    goToNextScreen();
  };

  const hideSkipQueue = (
    schoolModules?.find((module) => module?.plugins?.kanplaGo) || {}
  )?.plugins?.kanplaGo?.hideSkipQueue;

  return (
    <>
      <PageWrapper
        title={t("signup-flow:get-ready")}
        subtitle={t("signup-flow:add-card-and-take-your-food")}
        buttonTitle="Let's go"
        hideButton={true}
        isFromAnonymousFlow={isFromAnonymousFlow}
        isFromAPrivateModule={isFromAPrivateModule}
      >
        <div className="w-full h-full mt-16">
          <CardAddButtonWrapper
            onSuccess={() => continueFlow()}
            setLoading={setLoading}
          >
            <ButtonSignUp
              loading={loading}
              type="button"
              title={
                <>
                  {!loading && (
                    <FontAwesomeIcon icon={faPlus} className="mr-3" />
                  )}{" "}
                  {t("translation:add-card-long")}
                </>
              }
            />
          </CardAddButtonWrapper>
          <div className="mt-4 w-full">
            <Button className="w-full h-12" onClick={continueFlow}>
              {t("signup-flow:skip")}
            </Button>
          </div>
        </div>
      </PageWrapper>
      <KanplaGoIntro
        open={kanplaGoIntroOpen}
        setOpen={setKanplaGoIntroOpen}
        hideSkipQueue={hideSkipQueue}
      />
    </>
  );
};

export default AddCard;
