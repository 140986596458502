import { webViewVersion } from "@kanpla/system";
import { AnonymousPropsFlow } from "@kanpla/types";
import { Spinner } from "@kanpla/ui";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { SignUpFlowContext } from "..";
import { AppContext } from "../../contextProvider";
import SignupCarouselWrapper from "../../introduction/SignupCarouselWrapper";
import PageWrapper from "../PageWrapper";

const Completed = ({
  isFromAnonymousFlow = false,
  isFromAPrivateModule = false,
}: AnonymousPropsFlow) => {
  const { t } = useTranslation(["signup-flow", "translation"]);
  const router = useRouter();
  const { modules } = useContainer(AppContext);
  const { signupScreens, schoolId, child } = useContainer(SignUpFlowContext);

  const hasAnsweredToNotificationPermissions =
    typeof window !== "undefined" &&
    window?.["hasAnsweredToNotificationPermissions"] !== undefined &&
    JSON.parse(window?.["hasAnsweredToNotificationPermissions"]);

  const hasAnsweredToTrackingPermissions =
    typeof window !== "undefined" &&
    window?.["hasAnsweredToTrackingPermissions"] !== undefined &&
    JSON.parse(window?.["hasAnsweredToTrackingPermissions"]);

  const hasSystemScreens =
    !hasAnsweredToTrackingPermissions && !hasAnsweredToNotificationPermissions;
  const hasSignupScreens = !isEmpty(signupScreens);
  const hasScreens = hasSystemScreens || hasSignupScreens;

  const doesVersionSupportCarousel = webViewVersion >= 2;

  const showCarousel = doesVersionSupportCarousel && hasScreens;

  // Hide carousel if modules include kanplaGo
  const hasKanplaGo = modules.some((m) => m?.plugins?.kanplaGo?.active);

  const continueLink = hasKanplaGo
    ? "/app"
    : "/app?showIntroductionCarousel=true";

  // Go to the app immediately
  useEffect(() => {
    if (showCarousel) return;
    router.push(continueLink);
  }, [hasKanplaGo, showCarousel]);

  return showCarousel ? (
    <div className="w-full h-full fixed top-0 z-max">
      <SignupCarouselWrapper
        schoolId={schoolId}
        childId={child?.id}
        signupScreens={signupScreens}
        pushToUrl={continueLink}
        forcePushToUrl
      />
    </div>
  ) : (
    <PageWrapper
      hideButton={true}
      title={t("signup-flow:you-are-ready-now")}
      isFromAnonymousFlow={isFromAnonymousFlow}
      isFromAPrivateModule={isFromAPrivateModule}
      buttonTitle=""
    >
      <div className="w-full h-full flex justify-center items-center text-primary-main">
        <Spinner size="medium" useCurrentColor />
      </div>
    </PageWrapper>
  );
};

export default Completed;
