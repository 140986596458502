import { validateSelectors } from "@kanpla/system";
import { AnonymousPropsFlow } from "@kanpla/types";
import { SelectorsInput } from "@kanpla/ui";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { SignUpFlowContext } from "..";
import KanplaGoIntro from "../../modals/KanplaGoIntro";
import PageWrapper from "../PageWrapper";
import NameInput from "../partials/NameInput";

const AddChild = ({
  isFromAnonymousFlow = false,
  isFromAPrivateModule = false,
}: AnonymousPropsFlow) => {
  const { t } = useTranslation(["signup-flow", "modals"]);
  const {
    school,
    selectors,
    setSelectors,
    createChild,
    schoolModules,
    isSchoolForChildren,
  } = useContainer(SignUpFlowContext);

  const [kanplaGoIntroOpen, setKanplaGoIntroOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    try {
      setLoading(true);
      const isSelectorValid = validateSelectors(selectors, school?.selectors);

      if (isSchoolForChildren && !isSelectorValid)
        throw new Error(t("modals:fill-fields"));

      await createChild();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const hideSkipQueue = (
    schoolModules.find((module) => module?.plugins?.kanplaGo) || {}
  )?.plugins?.kanplaGo?.hideSkipQueue;

  return (
    <>
      <PageWrapper
        title={t("signup-flow:add-child")}
        subtitle={t("signup-flow:add-the-name-and-the-class")}
        buttonTitle={t("signup-flow:add-child")}
        onButtonSubmit={submit}
        buttonLoading={loading}
        isFromAnonymousFlow={isFromAnonymousFlow}
        isFromAPrivateModule={isFromAPrivateModule}
      >
        <div className="w-full h-full mt-16">
          <NameInput label={t("signup-flow:the-children-full-name")} />
          <SelectorsInput
            selectors={selectors}
            setSelectors={setSelectors}
            school={school}
          />

          <p className="text-text-secondary text-center text-sm my-4">
            {t("signup-flow:you-can-add-more-children-later")}
          </p>
        </div>
      </PageWrapper>
      <KanplaGoIntro
        open={kanplaGoIntroOpen}
        setOpen={setKanplaGoIntroOpen}
        hideSkipQueue={hideSkipQueue}
      />
    </>
  );
};

export default AddChild;
