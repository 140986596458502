import { faCalendarDay } from "@fortawesome/pro-light-svg-icons";
import { fetchMultipleDocuments, fn } from "@kanpla/system";
import {
  AnonymousPropsFlow,
  FlexStandard,
  Module,
  Product,
} from "@kanpla/types";
import { EditStandardOrder } from "@kanpla/ui";
import { Button, message, Select } from "antd";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContainer } from "unstated-next";
import { SignUpFlowContext } from "..";
import PageWrapper from "../PageWrapper";

interface Props extends AnonymousPropsFlow {
  module: Module;
}

const StandardOrder = ({
  isFromAnonymousFlow = false,
  isFromAPrivateModule = false,
  module,
}: Props) => {
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation([
    "signup-flow",
    "translation",
    "introduction",
    "flex-bulk",
    "libs",
  ]);
  const { child, schoolId, goToNextScreen, setCurrentPage, hasPublicSchools } =
    useContainer(SignUpFlowContext);
  const [products, setProducts] = useState<Array<Product>>([]);
  const [productsLoading, setProductsLoading] = useState(false);
  const [standard, setStandard] = useState<FlexStandard["standard"]>({});

  /** Disabled because if was breaking the "invitation-link" flow for DABBA
   * Let's look at this together @petrmrkvicka
   *
   * Yours sincerely,
   * @robertkanpla
   * 20.7.2022
   */

  // useEffect(() => {
  //   if (child?.id && schoolId) return;
  //   setCurrentPage(hasPublicSchools ? "salesplace" : "email");
  // }, [child?.id, schoolId]);

  useEffect(() => {
    (async () => {
      try {
        if (!module?.id) throw new Error("Missing module");
        setProductsLoading(true);
        const productIds = Object.keys(module.products || {});
        const productsLoaded = await fetchMultipleDocuments<Product>(
          "products",
          productIds
        );

        setProducts(productsLoaded);
      } catch (e) {
        console.error(e);
        message.error(e?.message);
      } finally {
        setProductsLoading(false);
      }
    })();
  }, [module]);

  const submit = async () => {
    try {
      message.loading(
        isEmpty(i18n)
          ? "Standarder gemmes"
          : t("translation:message.loading.standards-are-saved"),
        0
      );
      setOpen(false);

      Object.values(standard || {})?.map((s) => {
        if (Object.keys(s)?.length > 1)
          throw new Error(t("translation:message.error.too-many-products"));
        return true;
      });

      // Delete empty lunches before submitting
      delete standard?.[0]?.no_lunch;
      delete standard?.[1]?.no_lunch;
      delete standard?.[2]?.no_lunch;
      delete standard?.[3]?.no_lunch;
      delete standard?.[4]?.no_lunch;

      const submitToServer = fn.httpsCallable("submitFlexStandard");
      await submitToServer({
        standard,
        schoolId,
        moduleId: module.id,
        userId: child.userId,
        childId: child.id,
      });

      message.destroy();
      message.success(
        isEmpty(i18n)
          ? "Standarder opdateret."
          : t("translation:message.success.standards-updated")
      );
    } catch (err) {
      console.log(err);
      message.error(
        err?.message ||
          (isEmpty(i18n)
            ? "Ændringer kunne ikke gemmes."
            : t("translation:message.error.changes-could-not-be-saved"))
      );
    }
  };

  return (
    <PageWrapper
      buttonTitle={t("translation:continue")}
      title={t("introduction:enter-default-order")}
      subtitle={t("flex-bulk:set-default-choice")}
      topIllustration={faCalendarDay}
      onButtonSubmit={submit}
      isFromAnonymousFlow={isFromAnonymousFlow}
      isFromAPrivateModule={isFromAPrivateModule}
    >
      {!productsLoading && (
        <div className="w-full h-full my-10 text-primary-dark">
          <Select
            size="large"
            placeholder={t("translation:select-product")}
            className="w-full"
            options={[
              { name: t("translation:no-breakfast"), id: "no_lunch" },
              ...products,
            ].map((p) => ({ label: p.name, value: p.id }))}
            onChange={(value) =>
              setStandard(
                Object.fromEntries(
                  [0, 1, 2, 3, 4].map((day) => [
                    day,
                    { [value]: { amount: 1 } },
                  ])
                )
              )
            }
          />
          <Button
            type="link"
            onClick={() => setOpen(true)}
            className="text-text-primary my-2 text-sm w-full"
          >
            {t("libs:edit-default-choice")}
          </Button>
          <EditStandardOrder
            fn={fn}
            schoolId={schoolId}
            moduleId={module?.id}
            childId={child?.id}
            open={open}
            setOpen={setOpen}
            userId={child?.userId}
            products={products}
            standard={{ ...standard }}
            callback={() => goToNextScreen()}
          />
        </div>
      )}
    </PageWrapper>
  );
};

export default StandardOrder;
